<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="ON BOARDING CHARGES"
          :breadcrumb="[
            {
              label: 'On Boarding Charg',
            },
            { label: 'On Boarding Charge' },
          ]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card custom-card"
              
            >
            <div class="card-body">
                <div class="row">
                  <div class="col text font">
                    <h4>
                      <b style="color: #00364f; font-weight: bolder"
                        >On Boarding Charges</b
                      >
                    </h4>
                  </div>
                  <div class="col text-end ">
                    <button
                    title="Add New"
                      @click="newHeading"
                      class="btn text-white btn-sm"
                      style="background-color: red"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <b>Add New</b>
                    </button>
                    <!-- &nbsp;<button type="button" data-bs-toggle="modal" data-bs-target="#new-task-modal" title="Filter"
                      class="btn text-white btn-sm" style="background-color: #00364f"> <strong> <font-awesome-icon
                          icon="filter" /></strong></button> -->

                  </div>
                  <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1" style="margin-top:-5px">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">

                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>



                <div class="row">
                  <div class="d-md-none d-xl-none d-block col-3 text font">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>

                    </select>
                  </div>

                </div>










                <!-- start list area  -->
                <div class="row" style="margin-top:5px">
                 
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                  
                  >
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th style="width: 3%">+</th>
                          <th style="width: 10%">Code</th>
                          <th style="width: 25%">Admin</th>
                          <th style="width: 10%">Charges</th>
                          <th style="width: 45%">Remark</th>
                          <th class="text-end" style="width: 10%">Action</th>
                        </tr>

                        <tbody>
                          <tr
                            style="color: #00364f"
                            v-for="(charge, index) in onBoardingCharges"
                            :key="index"
                          >
                            <td>
                              <img
                                style="width: 8px; margin-right: 5px"
                                src="/assets/image/record.png"
                                alt=""
                              />
                            </td>

                            <td v-if="charge.admin" class="text-truncate">{{ charge.admin.code }}</td>
                            <td v-if="charge.admin" class="text-truncate">{{ charge.admin.orgnization }}</td>
                            <td v-if="charge" class="text-truncate">{{ charge.onBoardingCharge }}</td>
                            <td>{{ charge.remark }}</td>
                            <td class="text-end">
                              <div class="btn-group btn-group-sm" role="group">
                                <button
                                  title="Edit"
                                  type="button"
                                  class="btn btns btn-sm btn-success"
                                  @click="OnBoardingChargesEdit(charge)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                >
                                  <font-awesome-icon icon="edit" />
                                </button>

                                <!-- <button
                                  type="button"
                                  @click="OnBoardingChargesDelete(charge)"
                                  class="btn btns btn-sm btn-danger"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                >
                                  <font-awesome-icon icon="trash" />
                                </button> -->
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
              <div class="card-footer">
                <div class="row">

                  <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                    <p class="mb-0">
                      Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                    </p>
                  </div>
                  <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                    <ul class="pagination  justify-content-center">
                      <li class="page-item" @click="onBoardingChargeLoad(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="onBoardingChargeLoad(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 3"
                        @click="onBoardingChargeLoad(pagination.current_page - 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 2"
                        @click="onBoardingChargeLoad(pagination.current_page - 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 1"
                        @click="onBoardingChargeLoad(pagination.current_page - 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li class="active page-item" @click="onBoardingChargeLoad(pagination.current_page)">
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                        @click="onBoardingChargeLoad(pagination.current_page + 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                        @click="onBoardingChargeLoad(pagination.current_page + 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                        @click="onBoardingChargeLoad(pagination.current_page + 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li>
                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="onBoardingChargeLoad( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                      <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>

                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="onBoardingChargeLoad( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                        @click="onBoardingChargeLoad(pagination.last_page - 3)">
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                        @click="onBoardingChargeLoad(pagination.last_page - 2)">
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                        @click="onBoardingChargeLoad(pagination.last_page - 1)">
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="onBoardingChargeLoad(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="onBoardingChargeLoad(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul>
                  </div>

                  <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- modal for add and edit member addons -->
  <!-- Button trigger modal -->

  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modaltitle" style="color: #00364f">
            <b>Create New Profassional Type </b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <!-- (edit == 'false') ? stepsNewCreate() : stepsUpdate() -->
        <div class="modal-body">
          <!-- (edit == 'false') ? saveProfassionalType() : profassionalTypeUpdate() -->
          <form
            @submit.prevent="
              edit == 'false' ? saveOnBoardingCharges() : OnBoardingChargesUpdate()
            "
          >
            <div class="container">
              <div class="form-group">
                <label>Business Name <samll style="color:#f21000">*</samll></label>
                <select
                  
                  class="form-select"
                  v-model="form.admin_id"
                  required
                >
                  <option v-for="(admin, index) in admins" :key="index" :value="admin.id">
                  <div>
            <strong> {{ admin.code }} </strong>
                   
                    {{ admin.orgnization }}
             
           
                 
                    ({{ admin.name }})
                  </div>
                  
                  </option>
                </select>
                <small style="color: red" v-if="form.errors.error">
                  {{ form.errors.error.admin_id[0] }}</small
                >
              </div>
              <div class="form-group mt-1">
                <label>Charges <samll style="color:#f21000">*</samll></label>
                <input
                  required
                  
                  type="number"
                  class="form-control"
                  placeholder="On Boarding Charge"
                  v-model="form.onBoardingCharge"
                />
                <!-- <span style="color: #f21000" id="typeName"></span> -->
                <small style="color: red" v-if="form.errors"
                  ><span v-if="form.errors.error"
                    ><span v-if="form.errors.error.onBoardingCharge">{{
                      form.errors.error.onBoardingCharge[0]
                    }}</span></span
                  ></small
                >
              </div>
              <div class="form-group mt-1">
                <label>Remark</label>
                <textarea
                  
                  class="form-control"
                  rows="4"
                  placeholder="remark"
                  v-model="form.remark"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn text-white btn-sm"
                style="background-color: red"
                data-bs-dismiss="modal"
              >
                <b>Close</b>
              </button>
              <button
                type="submit"
                class="btn text-white btn-sm"
                style="background-color: #00364f"
              >
                <strong>Confirm</strong>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end modal addons  -->
</template>

//
<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Banner from "../../../components/super-admin/comman/Banner.vue";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: " OnBoardingCharges",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,
      loading: false,
      edit: "false",
      form: new Form({
        onBoardingCharge: "",
        admin_id: null,
        remark: "",
        errors: {},
      }),
      onBoardingCharges: [],
      admins: [],
      charge_id: null,
    };
  },
  methods: {
    changePageNo(e) {
      this.per_page = e.target.value;
      this.onBoardingChargeLoad();
    },
    newHeading() {
      this.form = {};
      this.form.errors = {};
      this.edit = "false";
      $("#modaltitle").html("<b>Create Charge</b>");

      this.form.admin_id = this.findMinVal(this.admins);
    },
    findMinVal(data) {
      var arr = [];
      for (var i = 0; i < data.length; i++) {
        arr.push(data[i].id);
      }
      var r = Math.min.apply(Math, arr);
      return r;
    },
    OnBoardingChargesDelete(charge) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Delete it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.$axios
              .delete(`superadmin/clientonboardingcharge/${charge.id}`, {
                headers: { Authorization: "Bearer " + localStorage.accessToken },
              })
              .then((res) => {
                res;
                toast.success("On Boarding Charge Deleted  Succesfully", {
                  autoClose: 1000,
                });
                this.onBoardingChargeLoad();
              })
              .finally(() => (this.loading = false))
              .catch((error) => {
                error;
              });
          }
        });
    },

    OnBoardingChargesUpdate() {
      this.loading = true;
      this.$axios
        .put(`superadmin/clientonboardingcharge/${this.charge_id}`, this.form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          toast.success("On Boarding Charge Update Succesfully", {
            autoClose: 1000,
          });
          this.form = {};
          this.form.errors = {};
          this.onBoardingChargeLoad();
          $("#exampleModal").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          this.form.errors = error.response.data;
        });
    },
    OnBoardingChargesEdit(charge) {
      this.form = {};
      this.form.errors = {};
      $("#modaltitle").html("<b>Update On Boarding Charge</b>");
      this.charge_id = charge.id;
      this.form.admin_id = charge.admin_id;
      this.form.onBoardingCharge = charge.onBoardingCharge;
      this.form.remark = charge.remark;
      this.edit = "true";
    },
    saveOnBoardingCharges() {
      this.loading = true;
      this.$axios
        .post("superadmin/clientonboardingcharge", this.form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          toast.success("On Boarding Charge Created Succesfully ", {
            autoClose: 1000,
          });
          this.form = {};
          this.form.errors = {};
          this.onBoardingChargeLoad();
          $("#exampleModal").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          this.form.errors = error.response.data;
        });
    },
    onBoardingChargeLoad(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
        pageUrl += `superadmin/clientonboardingcharge?per_page=${this.per_page}`;
      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.onBoardingCharges = res.data.data.data;
          this.pagination = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    loadAdmins() {
      this.$axios
        .get("superadmin/admin?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.admins = res.data.data;
        });
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.superAdminPageTitles.onBoard
    console.log(this.$store.state.superAdminPageTitles.onBoard)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.onBoardingChargeLoad();
      this.loadAdmins();

      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}



.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 0px 10px 0px 10px;
}
</style>
